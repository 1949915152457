import * as React from "react";

const Component = ({ location }) => {
  React.useEffect(() => {

    window.location.href  = `/`;
    
    })
  return null;
}

export default Component
